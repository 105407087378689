import { useEffect } from 'react';
import { connect } from 'react-redux';
import { FACEBOOK_PIXEL_APP_ID, DEBUG } from '../constants/index';

const FacebookPixel = ({ fbPixelId, externalId }) => {
  useEffect(() => {
    if (!fbPixelId || !externalId) return;
    /* eslint-disable */
    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions,no-unused-expressions,no-multi-assign,no-param-reassign
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', FACEBOOK_PIXEL_APP_ID, {
      external_id: externalId
    }, {
      debug: DEBUG
    });
    fbq('track', 'PageView');
    /* eslint-enable */
  }, [fbPixelId, externalId]);

  return null;
};

const mapState = state => ({
  externalId: state?.user?.data?.externalId,
  fbPixelId: FACEBOOK_PIXEL_APP_ID
});

export default connect(mapState)(FacebookPixel);
