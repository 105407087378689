import { init } from '@rematch/core';
import user from './user';

const config = {
  models: {
    user
  }
};

const store = init(config);

export default store;
