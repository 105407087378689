import amplitude from 'amplitude-js';
import { DEBUG, AMPLITUDE_API_KEY } from '../constants/index';
import packageInfo from '../../package.json';

const Symbol = require('es6-symbol');

const services = Symbol();
const servicesEnforcer = Symbol();

class Tracker {
  static get instance() {
    if (!this[services]) {
      this[services] = new Tracker(servicesEnforcer);
    }
    return this[services];
  }

  static set instance(_) {
    throw new Error("Can't change constant property!");
  }

  constructor() {
    // const service = this;
    this.amplitude = (event, data = {}) => {
      if (event instanceof Array) {
        // eslint-disable-next-line no-param-reassign
        [event, data] = [...event];
      }

      // eslint-disable-next-line no-param-reassign
      data = { ...{ userProps: {} }, ...data };

      // eslint-disable-next-line prefer-const
      let { userProps, screen, userId, ...otherData } = data;

      if (screen) localStorage.setItem('screen_id', screen);
      else screen = localStorage.getItem('screen_id');

      const amplitudeData = {
        ...otherData,
        ...{ debug: DEBUG, screen, microtime: window.performance.now() }
      };

      // запретить трекать одинаковые события
      // if ((service?.['prevEvent'] || '') === event && JSON.stringify(service?.['prevEventData']||{}) === JSON.stringify(amplitudeData) ) return;

      // service['prevEvent'] = event;
      // service['prevEventData'] = JSON.stringify(amplitudeData);

      if (DEBUG) {
        console.log('amplitude', event, amplitudeData);
      }

      if (AMPLITUDE_API_KEY) {
        const { version } = packageInfo;
        amplitude.getInstance().init(AMPLITUDE_API_KEY);
        amplitude.getInstance().setVersionName(version);
        if (userId) amplitude.getInstance().setUserId(userId);

        const identify = new amplitude.Identify();

        Object.keys(userProps || {}).map(name => identify.setOnce(name, userProps[name]));

        amplitude.getInstance().identify(identify);

        amplitude.getInstance().logEvent(event, amplitudeData);
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  log(...data) {
    if (DEBUG) {
      console.log(...data);
    }
  }
}

const trackAmplitude = (...data) => Tracker.instance.amplitude(data);

export default trackAmplitude;

export const handleTrack = name => trackAmplitude(name);
