import React, { useEffect, useState, useRef } from 'react';
import camelcaseKeys from 'camelcase-keys';
import decamelizeKeys from 'decamelize-keys';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ONE_LINK_PARAMS,
  // DEFAULT_FLOWS,
  DEFAULT_SCREEN,
  DEBUG,
  CONTENT_TYPE_JSON,
  HTTP_METHOD_POST,
  STORAGE_USER_KEY
} from './constants';
import { useQuery } from './hooks/useQuery';
import './App.css';
import {
  PERFORMANCE,
  WEB_BACK_ERROR,
  WEB_FIRST_MAIN_OPEN,
  WEB_IMPORT_VIEW_ERROR,
  WEB_SERVER_REG_RESPONSE
} from './constants/AmplitudeTrackEvents';
import trackAmplitude from './services/analytics';
import { buildOneLinkDownloadUrl, normalizeCookie } from './services/helpers';

const App = ({ update }) => {
  const t0 = window.performance.now();
  const timerRef = useRef();
  const [views, setViews] = useState(null);
  const [cookie, setCookie] = useState(null);
  const query = useQuery();
  const { pathName = 'default' } = useParams();
  const flow = query.get('flow') || 'default';
  const isDebug = query.get('debug') === 'true';

  ONE_LINK_PARAMS.forEach((keyName, i) => {
    const val = query.get(keyName) || query.get(i + 1);
    if (!isEmpty(val)) {
      localStorage.setItem(keyName, val);
    }
  });

  useEffect(() => {
    fetch(`/web/landing_register?flow=${flow}`)
      .then(r => {
        return r.json().then(r => camelcaseKeys(r));
      })
      .then(({ externalId, isFirstQuery, screenId, userId }) => {
        update({ externalId, isFirstQuery, screenId, userId });

        let renderScreen = screenId;
        DEBUG && console.log({ externalId, isFirstQuery, renderScreen, userId });
        localStorage.setItem(STORAGE_USER_KEY, userId);

        const t1 = window.performance.now();
        const timeMs = Math.ceil(t1 - t0);

        trackAmplitude(WEB_SERVER_REG_RESPONSE, { timeMs });

        if (isFirstQuery)
          trackAmplitude(
            WEB_FIRST_MAIN_OPEN,
            decamelizeKeys({
              screen: renderScreen,
              userId,
              timeMs
            })
          );

        if (isDebug || renderScreen === DEFAULT_SCREEN) renderScreen = flow?.split('/')[0];

        let viewLoading = -1;
        const t3 = window.performance.now();
        import(`./views/${renderScreen}/index`)
          .then(r => {
            const t2 = window.performance.now();
            viewLoading = Math.ceil(t2 - t3);
            trackAmplitude(PERFORMANCE, {
              backendResponse: Math.ceil(t1 - t0),
              viewLoading
            });
            return r;
          })
          .then(r => setViews(<r.default pathName={pathName} />))
          .catch(e => {
            trackAmplitude(WEB_IMPORT_VIEW_ERROR, { error: e.message });
          });
      })
      .catch(e => {
        trackAmplitude(WEB_BACK_ERROR, { error: e.message });
        timerRef.current && clearInterval(timerRef.current);
      });
  }, []);

  useEffect(() => {
    const nCookie = normalizeCookie(document?.cookie);

    if (views !== null) {
      if (!!document?.cookie && cookie !== nCookie) {
        setCookie(nCookie);
        fetch('/web/cookies', {
          method: HTTP_METHOD_POST,
          headers: {
            'Content-Type': CONTENT_TYPE_JSON
          },
          body: JSON.stringify({ cookie: nCookie })
        })
          .then(r => r)
          .then(() => {
            const downloadOneLink = buildOneLinkDownloadUrl({ pathName });
            window.location.replace(downloadOneLink);
          })
          .catch(() => setCookie(Date.now()));
      }
    }
  }, [views, cookie]);

  return <>{views}</>;
};

const mapState = state => ({
  user: state.user
});

const mapDispatch = ({ user: { update } }) => ({
  update
});

export default connect(mapState, mapDispatch)(App);
