export const WEB_SERVER_REG_RESPONSE = 'web_server_reg_response';
export const WEB_IMPORT_VIEW_ERROR = 'web_import_view_error';

export const WEB_DOM_CONTENT_LOADED = 'web_dom_loaded';
export const WEB_LOAD = 'web_load';
export const PERFORMANCE = 'performance';
export const WEB_BACK_ERROR = 'web_back_error';
export const WEB_GET_REQUEST_TIMEOUT = 'web_get_request_timeout';
export const WEB_USER_RELOAD_PAGE = 'web_user_reload_page';

export const WEB_FIRST_MAIN_OPEN = 'web_first_main_open';
export const WEB_MAIN_OPEN = 'web_main_open';
export const WEB_OPEN_FAILURE = 'web_open_failure';
export const WEB_TERMS_CLICK = 'web_terms_click';
export const WEB_PRIVACY_CLICK = 'web_privacy_click';
export const WEB_MONEYBACK_CLICK = 'web_moneyback_click';
export const WEB_FAQ_CLICK = 'web_faq_click';

export const WEB_MAIN_START = 'web_main_start';
export const WEB_MAIN_START_DISABLED = 'web_main_start_disabled';

export const WEB_ANIMATED_EFFECTS_OPEN = 'web_animated_effects_open';
export const WEB_ANIMATED_EFFECTS_START = 'web_animated_effects_start';

export const WEB_AI_RETOUCHING_OPEN = 'web_ai_retouching_open';
export const WEB_AI_RETOUCHING_START = 'web_ai_retouching_start';

export const WEB_LIGHTROOM_MOBILE_COURSE_OPEN = 'web_lightroom_mobile_course_open';
export const WEB_LIGHTROOM_MOBILE_COURSE_START = 'web_lightroom_mobile_course_start';

export const WEB_CHOOSE_CATEGORIES_OPEN = 'web_choose_categories_open';
export const WEB_CHOOSE_CATEGORIES_CONTINUE = 'web_choose_categories_continue';

export const WEB_CHOOSE_EFFECTS_OPEN = 'web_choose_effects_open';
export const WEB_CHOOSE_EFFECTS_CONTINUE = 'web_choose_effects_continue';

export const WEB_AI_RETOUCH_OPEN = 'web_ai_retouch_open';
export const WEB_AI_RETOUCH_APPLY = 'web_ai_retouch_apply';

export const WEB_IMAGE_COLORIZER_OPEN = 'web_image_colorizer_open';
export const WEB_IMAGE_COLORIZER_APPLY = 'web_image_colorizer_apply';

export const WEB_WANT_AI_CHOOSE_5_BEST_OPEN = 'web_want_ai_choose_5_best_open';
export const WEB_WANT_AI_CHOOSE_5_BEST_APPLY = 'web_want_ai_choose_5_best_apply';
export const WEB_WANT_AI_CHOOSE_5_BEST_CHOOSE_PHOTO = 'web_want_ai_choose_5_best_choose_photo';
export const WEB_WANT_AI_CHOOSE_5_BEST_SWIPE_COMPARE = 'web_want_ai_choose_5_best_swipe_compare';

export const WEB_WANT_TO_MASTER_SKILL_OPEN = 'web_want_to_master_skill_open';
export const WEB_WANT_TO_MASTER_SKILL_APPLY = 'web_want_to_master_skill_apply';

export const WEB_TIME_TO_FINALIZE_OPEN = 'web_time_to_finalize_open';

export const WEB_GET_USER_EMAIL_OPEN = 'web_get_user_email_open';

export const WEB_DESCRIPTION_OPEN = 'web_description_open';
export const WEB_DESCRIPTION_CLICK = 'web_description_click';

export const WEB_PRE_SUBSCRIPTION_OPEN = 'web_pre_subscription_open';
export const WEB_PRE_SUBSCRIPTION_CONTINUE = 'web_pre_subscription_continue';

export const WEB_USER_REGISTER = 'web_user_register';

export const WEB_VIP_OPEN = 'web_VIP_open';
export const WEB_PAYMENT_METHOD_CLICK = 'web_payment_method_click';

export const WEB_QUIZ_CURRENT_WEIGHT_HEIGHT = 'web_quiz_current_weight_height';
export const WEB_QUIZ_DESIRED_WEIGHT = 'web_quiz_desired_weight';
export const WEB_QUIZ_AGE_OPEN = 'web_quiz_age_open';
export const WEB_QUIZ_GENDER = 'web_quiz_gender';
export const WEB_QUIZ_IF_EXPERIENCE = 'web_quiz_if_experience';
export const WEB_QUIZ_MEDICAL_CONDITIONS_FEMALE = 'web_quiz_medical_conditions_female';
export const WEB_QUIZ_MEDICAL_CONDITIONS_GENERAL = 'web_quiz_medical_conditions_general';
export const WEB_QUIZ_LOADING_ANALYZE = 'web_quiz_loading_analyze';
export const WEB_HEALTH_ADVICE_OPEN = 'web_health_advice_open';
export const WEB_HEALTH_ADVICE_CLICK = 'web_health_advice_click';

export const WEB_VIP_START_CLICK = 'web_VIP_start_click';
export const WEB_PAYMENT_METHOD_OPEN = 'web_payment_method_open';

export const WEB_PAYMENT_METHOD_OFFER_CLICK = 'web_payment_method_offer_click';
export const WEB_PAYMENT_METHOD_CHOSEN = 'web_payment_method_chosen';
export const WEB_SPECIAL_OFFER_OPEN = 'web_special_offer_open';
export const WEB_SPECIAL_OFFER_CHOSEN = 'web_special_offer_chosen';
export const WEB_SIGN_UP_OPEN = 'web_sign_up_open';
export const WEB_SIGN_UP_COMPLETED = 'web_sign_up_completed';
export const WEB_DOWNLOAD_INSTRUCTIONS_OPEN = 'web_download_instructions_open';
export const WEB_MANAGE_ACCOUNT_OPEN = 'web_manage_account_open';
export const WEB_SUBSCRIPTION_CANCEL_CONFIRMATION_OPEN =
  'web_subscription_cancel_confirmation_open';
export const WEB_DOWNLOAD_CLICK = 'web_download_click';

export const WEB_PURCHASE_ANALYTICS = 'web_purchase_analytics';
export const WEB_PURCHASE_ERROR = 'web_purchase_error';
export const WEB_ERROR = 'web_error';
// export const WEB_SUBSCRIPTION                   = 'web_subscription';
export const WEB_SUBSCRIPTION_CANCEL = 'web_subscription_cancel';
// export const WEB_SUBSCRIPTION_TRIAL             = 'web_subscription_trial'
// export const WEB_SUBSCRIPTION_UPGRADE           = 'web_subscription_upgrade'
