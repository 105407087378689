export const DOWNLOAD_ONE_LINK = '//hubapp.onelink.me/ZKxz?pid=FB_W2A_iOS15';
export const DOWNLOAD_LINK = 'https://grids.growthphotographers.com/go/wl:';
export const DEFAULT_FLOWS = ['BGVideo', 'Simple'].join('/');
export const ONE_LINK_PARAMS = ['c', 'af_adset', 'af_ad'];
export const CONTENT_TYPE_JSON = 'application/json;charset=utf-8';
export const DEFAULT_SCREEN = 'default';
export const HTTP_METHOD_POST = 'post';
export const STORAGE_USER_KEY = 'userId';
export const FACEBOOK_PIXEL_APP_ID = process.env.REACT_APP_FACEBOOK_PIXEL_APP_ID;
export const DEBUG = parseInt(process.env.REACT_APP_DEBUG) === 1;
export const AMPLITUDE_API_KEY = DEBUG
  ? process.env.REACT_APP_AMPLITUDE_API_DEV_KEY
  : process.env.REACT_APP_AMPLITUDE_API_KEY;

export default {
  DOWNLOAD_ONE_LINK,
  DEFAULT_FLOWS,
  ONE_LINK_PARAMS,
  CONTENT_TYPE_JSON,
  DEFAULT_SCREEN,
  AMPLITUDE_API_KEY,
  DEBUG,
  HTTP_METHOD_POST,
  STORAGE_USER_KEY,
  FACEBOOK_PIXEL_APP_ID,
  DOWNLOAD_LINK
};
