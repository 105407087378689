import { CONTENT_TYPE_JSON, HTTP_METHOD_POST } from '../constants/index';

export default {
  state: {
    pending: false,
    data: {
      email: null
    }
  },
  reducers: {
    updateState: (state, data) => {
      return {
        ...state,
        ...data,
        pending: false
      };
    },
    updateUserEmail: (state, newData) => {
      const { data } = state;
      return {
        ...state,
        data: {
          ...data,
          ...newData
        },
        pending: false
      };
    }
  },
  effects: dispatch => ({
    update: data => {
      dispatch.user.updateState({ data });
    },
    sendEmail: async email => {
      const headers = {
        'Content-Type': CONTENT_TYPE_JSON
      };

      fetch(`/web/landing_register`, {
        method: HTTP_METHOD_POST,
        headers,
        body: JSON.stringify({ email })
      })
        .then(() => {
          dispatch.user.updateUserEmail({ email });
        })
        .catch(e => {
          console.log('Fetch catch error', e);
        });
    }
  })
};
