import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './models';
import FacebookPixel from './services/FacebookPixel';
import './index.css';

window.ResizeObserver = window.ResizeObserver || ResizeObserver;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FacebookPixel />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/:pathName" element={<App />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
