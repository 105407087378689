import memoizeOne from 'memoize-one';
import { isEmpty } from 'lodash';
import { STORAGE_USER_KEY, ONE_LINK_PARAMS, DOWNLOAD_LINK } from '../constants';

export const normalizeCookie = memoizeOne(cookie =>
  cookie
    ?.split(';')
    ?.reduce((prevVariants, variant) => {
      prevVariants.push(variant.trim());
      return prevVariants;
    }, [])
    ?.sort()
    ?.join('; ')
    ?.trim()
);

export const setCustomCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;

  return document.cookie;
};

export const buildOneLinkDownloadUrl = ({ pathName }) => {
  const userId = localStorage.getItem(STORAGE_USER_KEY);

  const queryParamsObject = ONE_LINK_PARAMS.reduce((p, c) => {
    const val = localStorage.getItem(c);
    if (!isEmpty(val)) return `${p}&${c}=${val}`;
    return p;
  }, `&af_sub4=${userId}`);
  return `${DOWNLOAD_LINK}${pathName}?${queryParamsObject}`;
};
